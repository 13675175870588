<template>
  <div>
    <container-form
      :show-left-icon="rejectedCompany"
      img="images/dashboard/offer-analysis.svg"
      :icon="
        rejectedCompany ? 'images/dashboard/padlock-danger.svg' : 'images/dashboard/padlocks.svg'
      "
    >
      <template #form>
        <div v-if="!rejectedCompany" class="d-flex justify-content-start">
          <b-img src="@/assets/images/credito-frete-check-hand.svg" class="mr-2" />
          <div>
            <h2 class="mb-1">Obrigado por enviar suas informações.</h2>
            <h4 class="mt-1">
              Suas informações já estão em processo de análise. Fique atento ao seu email, em breve
              você receberá um comunicado atualizando você sobre o status da sua análise de crédito.
            </h4>
          </div>
        </div>
        <div v-else>
          <div class="d-flex flex-row justify-content-start">
            <div class="d-flex flex-column align-items-start">
              <h4 class="text-dark">Oferta não encontrada</h4>
              <p>
                Não encontramos uma linha de crédito adequada para o seu perfil neste momento.
                Destacamos que você pode tentar uma nova análise em 90 dias.
              </p>
              <b-button
                :disabled="!canTryAgain"
                variant="primary"
                @click="$router.push({ name: 'dashboard' })"
                ><feather-icon icon="LockIcon" class="mr-1" /> Tentar Novamente</b-button
              >
            </div>
          </div>
        </div>
      </template>
    </container-form>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BImg, BButton } from 'bootstrap-vue'
import ContainerForm from '@/views/client/dashboard/components/manual_offer/ContainerForm.vue'
import CompleteRegistrationAlert from '@/views/client/dashboard/components/CompleteRegistrationAlert.vue'

export default {
  name: 'OfferStatus',
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BButton,
    ContainerForm,
    CompleteRegistrationAlert,
  },
  data() {
    return {
      analysisResult: {},
    }
  },
  computed: {
    currentCompany() {
      return this.$store.state.auth.userData.empresa
    },
    rejectedCompany() {
      if (this.analysisResult === null) return false

      const rating = parseFloat(this.analysisResult.rating)
      const limit = parseFloat(this.analysisResult.limite)

      return (
        (this.analysisResult !== null && rating <= 200) ||
        (this.analysisResult !== null && limit === 0)
      )
    },
    showCompleteRegistration() {
      if (this.analysisResult === null) return false

      return parseFloat(this.analysisResult.limite) >= 10000
    },
    canTryAgain() {
      /* checa se dia atual é igual ou maior que a data da ultima analise +90 dias */
      const lastAnalysisDate = new Date(this.analysisResult.updated_at)
      const today = new Date()
      lastAnalysisDate.setDate(lastAnalysisDate.getDate() + 90)
      return today.getTime() >= lastAnalysisDate.getTime()
    },
  },
  async mounted() {
    await this.fetchAnalysisResult(this.currentCompany.id)
  },
  methods: {
    async fetchAnalysisResult(id) {
      const { data } = await this.$store.dispatch('company/getAnalysisResult', id)
      const { resultado } = data[0]
      if (resultado) this.analysisResult = resultado
    },
  },
}
</script>
