<template>
  <b-card v-if="registrationStatus" class="mb-0">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <b-img :src="registrationStatus.img" class="mr-2" />
      <div class="mr-1 wd-235 ">
        <h4>{{ registrationStatus.title }}</h4>
        <h6 class="mb-0 d-flex">
          {{ registrationStatus.subtitle }}
        </h6>
      </div>
      <b-button v-if="inAnalysis" variant="outline-primary" @click="$router.push({name: 'complete-registration'})">
        Continuar
      </b-button>
      <b-button v-if="pending" variant="primary" class="mt-2" @click="$router.push({name: 'minha.conta'})">
        Regularizar agora
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { BCard, BButton, BImg } from 'bootstrap-vue'

export default {
  name: 'CompleteRegistrationAlert',
  components: {
    BCard,
    BButton,
    BImg,
  },
  computed: {
    registrationStatus() {
      const status = {
        nao_enviado: {
          title: 'Complete seu cadastro',
          subtitle: 'Para seguir com a contratação é essencial que você conclua seu cadastro antes de solicitar qualquer operação.',
          // eslint-disable-next-line global-require
          img: require('@/assets/images/dashboard/chart.svg'),
        },
        em_validacao: {
          title: 'Cadastro em análise',
          subtitle: 'Suas informações estão em processamento. Em breve você será informado sobre esta análise.',
          // eslint-disable-next-line global-require
          img: require('@/assets/images/dashboard/clock.svg'),
        },
        reprovado: {
          title: 'Pendência no cadastro',
          subtitle: 'Regularizar o seu cadastro antes de prosseguir.',
          // eslint-disable-next-line global-require
          img: require('@/assets/images/dashboard/alert-octagon.svg'),
        },
      }
      return status[this.$store.state.auth.userData.empresa.status_documentacao]
    },
    inAnalysis() {
      return this.$store.state.auth.userData.empresa.status_documentacao === 'nao_enviado'
    },
    pending() {
      return this.$store.state.auth.userData.empresa.status_documentacao === 'reprovado'
    },
  },
}
</script>

<style lang="scss">
.asd {
  width: 60px;
  height: 60px;

  left: 7.99%;
  right: 8.68%;
  top: 9.25%;
  bottom: 7.5%;

  mix-blend-mode: normal;
  opacity: 0.2;
  /* 3) Bootstrap Color/Yellow */

  border: 4px solid #F8C33D;
}
.asd2{
  left: 91.32%;
  right: -74.56%;
  top: 92.5%;
  bottom: -75.75%;

  mix-blend-mode: normal;
  border: 4px solid #FFC633;
  transform: rotate(-180deg);
}
.wd-235{
  width: 235px;
}

</style>
